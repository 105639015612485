<template>
  <Layout>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <div class="single-course-area">

            <div class="course-top">
              <div class="sc-thumb d-inline-block">
                <img :src="staticImage + info.thumbnail" alt="">
              </div>
              <div class="d-inline-block vertical-align-top">
                <h4>{{ info.name }}</h4>
                <div class="course-meta">
                  <div class="author">
                    <img :src="authorImg" alt="">
                    <span>课程出品方</span>
                    <a href="#">ONE ORG</a>
                  </div>
                  <div class="categories" v-if="info.type === 1002 && !isBuy">
                    <span>学习有效期</span>
                    <a href="#">{{ info.day }}天</a>
                  </div>
                  <div class="categories" v-if="isBuy">
                    <span>学习有效期</span>
                    <a href="#">{{ expireTime }}</a>
                  </div>
                </div>

                <div class="course-price" style="background:rgba(63, 182, 95, 0.1)" v-if="info.type === 1001 ">
                  <p style="vertical-align: inherit;margin: 0;color: #3fb65f">
                    免费
                  </p>
                </div>

                <div class="course-price" v-if="info.type === 1002">
                  <p style="vertical-align: inherit;margin: 0">
                    ￥{{ info.price }}.00
                  </p>
                </div>

                <div class="course-price" style="background:rgba(126, 124, 135, 0.1)" v-if="info.type === 1003 ">
                  <p style="vertical-align: inherit;margin: 0;color: #7e7c87 ">
                    邀请制
                  </p>
                </div>

                <div class="course-buy" v-if="info.type === 1002 && !isBuy">
                  <router-link
                      class="theme-btn"
                      :to=" { path: '/order/add',query: { id: $route.query.id} } "
                  >
                    购买课程
                  </router-link>
                </div>
              </div>
            </div>


            <div class="course-tab-wrapper col-12">
              <b-tabs nav-class="course-tab-btn">
                <b-tab title="First">
                  <template #title>
                    <i class="uil uil-bookmark-full"></i>
                    课程介绍
                  </template>
                  <div class="introduce" v-html="info.introduce"></div>
                </b-tab>
                <b-tab title="Curriculum">
                  <template #title>
                    <i class="uil uil-book-alt"></i>
                    课程目录
                  </template>
                  <div id="id_1" class="curriculum-item ">
                    <div id="cc_1" class="card-header">
                      <h5 class="mb-0">
                        <button class="btn btn-link">
                          Course Catalog
                        </button>
                      </h5>
                    </div>
                    <div id="acc_1" aria-labelledby="cc_1" class="collapse show" data-parent="#id_1" style="">
                      <div class="card-body" :style="{'pointer-events': (!isBuy? 'none':'')}">
                        <div v-for="(item,index) in chapters" :key="item.id" :class="(index%2===0 ? 'with-bg' :'')"
                             class="ci-item">
                          <h5>
                            <i class="icon_menu-square_alt2"></i>

                            <router-link
                                @click.prevent=" false;"
                                :to=" { path: '/courses/view',query: { cid: $route.query.id ,ccid:item.id} } "
                            >
                              <i v-if="item.type === 0" class="uil uil-text-fields mr-2"></i>
                              <i v-if="item.type === 1" class="uil uil-video mr-2"></i>
                              <i v-if="item.type === 2" class="uil uil-dropbox mr-2"></i>
                              {{ index + 1 }}. {{ item.name }}
                            </router-link>


                          </h5>
                          <div class="ci-tools">
                            <i v-if="!isBuy" class="uil uil-lock-alt"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Annex">
                  <template #title>
                    <i class="uil uil-import"></i>
                    课程附件
                  </template>
                  <p class="text-center">无课程附件</p></b-tab>
                <b-tab title="Reviews">
                  <template #title>
                    <i class="uil uil-star"></i>
                    学习用户
                  </template>
                  <p class="text-center">
                    内容未公开
                  </p></b-tab>
              </b-tabs>

            </div>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  components: {Layout},
  data() {
    return {
      courseImg: require('../../../../assets/images/single-course/1.jpg'),
      authorImg: require('../../../../assets/images/home3/course/a1.png'),
      info: [],
      chapters: [],
      startTime: '',
      expireTime: '',
      isBuy: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {

      const vm = this

      this.$axios.get('/Course/GetCourse', {params: {id: this.$route.query.id}}).then((res) => {
        vm.info = res.data.data
      })

      this.$axios.get('/Course/GetChapterList', {params: {id: this.$route.query.id}}).then((res) => {
        vm.chapters = res.data.data
      })

      this.$axios.get('/Course/CheckValid', {params: {id: this.$route.query.id}}).then((res) => {
        if (res.data.code === 1001) {
          vm.isBuy = true
          vm.startTime = res.data.data.startTime
          vm.expireTime = res.data.data.expireTime
        } else {
          vm.isBuy = false
        }
      })

    }
  }
}
</script>

<style>
a {
  text-decoration: none
}

.introduce p {
  margin: 0;
  font-size: 18px;
}

.course-top {
  position: relative;
  margin: 0 0 55px;
  padding-right: 150px;
}

.course-top h4 {
  font-size: 30px;
  line-height: 44px;
  color: #2c234d;
  position: relative;
  margin: 0 0 18px;
}

.course-top .course-price {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 0;
  padding: 16px 22px 13px;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 700;
  color: #5838fc;
  background: rgba(88, 56, 252, 0.1);
}

.course-top .course-price span {
  font-size: 14px;
  font-weight: 600;
  color: #7e7c87;
  display: block;
  margin: 0 0;
}

.course-meta {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 18px;
}

.course-meta .author {
  position: relative;
  padding-left: 55px;
  margin: 0;
}

.course-meta .author img {
  position: absolute;
  left: 0;
  top: 0;
}

.course-meta .author span {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #7e7c87;
  margin: 0 0 4px;
}

.course-meta .author a {
  display: block;
  color: #2c234d;
  margin: 0;
  font-size: 14px;
}

.categories {
  border-left: 1px solid #c3c2c8;
  padding: 0 0 0 32px;
  margin: 0 0 0 30px;
  text-align: center;
}

.categories span {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #7e7c87;
  margin: 0 0 4px;
}

.categories a {
  display: block;
  color: #2c234d;
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.course-meta .ratings {
  display: block;
  margin: 0 0;
}

.course-meta .ratings span {
  display: block;
  margin: 0 0 -4px;
  line-height: 20px;
  color: #7e7c87;
}

.course-meta .author a:hover,
.categories a:hover {
  color: #5838fc;
}

.sc-thumb {
  position: relative;
  margin: 0 40px 0 0;
}

.sc-thumb img {
  width: 400px;
  border-radius: 10px;
}

.course-tab-wrapper {
  position: relative;
  border: 1px solid #ebecf2;
  border-radius: 10px;
  overflow: hidden;
}

.course-tab-btn {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: none;
  margin: 0 0 60px;
}

.course-tab-btn li {
  list-style: none;
  margin: 0;
  width: 25%;
}

.course-tab-btn li a {
  display: inline-block;
  width: 100%;
  text-align: center;

  background: #f7f8fa !important;
  position: relative;
  font-size: 13px;
  line-height: 55px;
  height: 55px;
  color: #5d5a67;
  font-weight: 600;
  padding: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom: 1px solid #ebecf2 !important;
  border-right: 1px solid #ebecf2 !important;
}

.course-tab-btn li a i {
  font-size: 18px;
  position: relative;
  top: 1px;
  margin-right: 9px;
}

.course-tab-btn li a:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 0;
  height: 4px;
  background: #5838fc;
  content: '';
  margin: auto;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.course-tab-btn li a.active,
.course-tab-btn li a:hover {
  border: none;
  color: #5838fc !important;
  border-bottom: none !important;
  background: #fff !important;
}

.course-tab-btn li a.active:after,
.course-tab-btn li a:hover:after {
  width: 100% !important;
}

.course-tab-btn li:last-child a {
  border-right: none !important;
}

.course-tab-wrapper .tab-content {
  position: relative;
  padding: 0 35px 30px;
}

.teacher-item-3 {
  position: relative;
  margin: 0 0 30px;
  width: 100%;
  min-height: 280px;
  padding-left: 300px;
}

.teacher-item-3 .teacher-thumb {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 260px;
  height: 280px;
}

.teacher-item-3 .teacher-thumb img {
  width: 100%;
  height: 100%;
}

.teacher-item-3 .teacher-meta {
  padding: 35px 0 0;
}

.teacher-item-3 .teacher-meta h5 a:hover {
  color: #5838fc;
}

.teacher-item-3 .teacher-meta span {
  display: block;
  font-size: 14px;
  color: #797682;
  font-weight: 600;
  margin: -6px 0 16px;
}

.teacher-item-3 .teacher-meta p {
  font-size: 14px;
  line-height: 24px;
  color: #5d5a67;
  font-weight: 400;
  margin: 0 0 27px;
}

.teacher-item-3 .teacher-social {
  position: relative;
  bottom: auto;
  transform: none;
  text-align: left;
}

.teacher-item-3 .teacher-social a {
  color: #7e7c87;
  line-height: 40px;
  margin: 0 3px;
  border: 2px solid #e5e6eb;
}

.teacher-item-3 .teacher-social a:hover {
  color: #ffffff;
  background: #5838fc;
  border-color: #5838fc;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.30);
  -moz-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.30);
  box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.30);
}

.overview-content {
  position: relative;
}

.overview-content h4 {
  font-size: 20px;
  margin: 0 0 18px;
}

.overview-content p {
  font-size: 14px;
  line-height: 26px;
  margin: 0 0 26px;
}

.overview-content ul {
  margin: 0;
  padding: 10px 0 0;
}

.overview-content ul li {
  list-style: none;
  position: relative;
  padding-left: 40px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #2c234d;
  margin: 0 0 24px;
}

.overview-content ul li i {
  position: absolute;
  left: 0;
  top: 0;
  color: #5838fc;
  font-size: 20px;
  line-height: 35px;
}

.overview-content ul li span {
  display: block;
  color: #5d5a67;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin: 14px 0 0;
}

.curriculum-item {
  position: relative;
  border: 1px solid #ebecf2;
  border-radius: 10px;
  margin: 0 0 50px;
}

.curriculum-item .card-header {
  padding: 23px 30px;
  margin: 0;
  background: transparent;
  border: none;
  border-radius: 0;
}

.curriculum-item h5 {
  color: #2c234d;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0;
}

.curriculum-item h5 button {
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-weight: inherit;
  color: inherit;
  text-align: inherit;
  vertical-align: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  border: none;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
}


.curriculum-item h5 button:hover {
  text-decoration: none;
  color: inherit;
}

.curriculum-item h5 button.collapsed:after {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.curriculum-item .card-body {
  margin: 0;
  padding: 0;
}

.ci-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 18px 30px 12px;
}

.ci-item:last-child {
  border-radius: 0 0 10px 10px;
}

.ci-item h5 {
  font-size: 16px;
  color: #5d5a67;
  font-weight: 400;
  margin: 0;
}

.ci-item h5 i {
  display: inline-block;
  font-size: 20px;
  margin-right: 8px;
}

.ci-item h5 a {
  color: inherit;
}

.ci-item h5:hover a {
  color: #5838fc;
}

.ci-tools a {
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.ci-tools .time {
  background: rgba(88, 56, 252, .1);
  color: #5838fc;
  border-radius: 3px;
  height: 30px;
  text-align: center;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 16px;
}

.ci-tools .time:hover {
  background: #5838fc;
  color: #fff;
}

.ci-tools .lock {
  font-size: 14px;
  line-height: 30px;
  color: #5d5a67;
}

.ci-tools .lock:hover {
  color: #5838fc;
}

.ci-item.with-bg {
  background: #f7f8fa;
}

.ci-tools .questions {
  background: rgba(63, 182, 95, .1);
  color: #3fb65f;
  border-radius: 3px;
  height: 30px;
  text-align: center;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 16px;
}

.ci-tools .questions:hover {
  background: #3fb65f;
  color: #fff;
}

.reviw-area {
  position: relative;
}

.reviw-area h4 {
  font-size: 20px;
  margin: 0 0 26px;
}

.reating-details {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 78px;
}

.average-rate {
  width: 26%;
}

.average-rate p {
  font-size: 14px;
  line-height: 28px;
  color: #5d5a67;
  margin: 0 0 15px;
}

.rate-box {
  width: 100%;
  height: 200px;
  background: #f7f8fa;
  border-radius: 5px;
  text-align: center;
  padding: 34px 15px;
}

.rate-box h2 {
  font-size: 60px;
  color: #5838fc;
  line-height: 60px;
  margin: 0 0 18px;
}

.rate-box .ratings {
  display: block;
  margin: 0 0 3px;
}

.rate-box .ratings i {
  font-size: 14px;
}

.rate-box .ratings i:last-child {
  color: #c4c2cb;
}

.rate-box span {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #5d5a67;
}

.details-rate {
  position: relative;
  width: 74%;
  margin-left: 30px;
}

.details-rate p {
  font-size: 14px;
  line-height: 28px;
  color: #5d5a67;
  margin: 0 0 15px;
}

.detail-rate-box {
  width: 100%;
  height: 200px;
  background: #f7f8fa;
  border-radius: 5px;
  padding: 36px 40px;
}

.rate-item {
  position: relative;
  padding-left: 25px;
  padding-right: 55px;
  margin: 0 0 24px;
}

.rate-item p {
  position: absolute;
  left: 0;
  top: -3px;
  font-size: 14px;
  line-height: .8;
  color: #2c234d;
  font-weight: 600;
  margin: 0;
}

.rate-item .progress {
  height: 6px;
  border-radius: 30px;
  background: #e1e4eb;
}

.rate-item .progress-bar {
  background: #5838fc;
  border-radius: 30px;
}

.rate-item span {
  position: absolute;
  right: 0;
  top: -3px;
  color: #7e7c87;
  font-size: 14px;
  line-height: .8;
  font-weight: 600;
}

.review-rating {
  position: relative;
}

.review-rating > h5 {
  font-size: 20px;
  margin: 0 0 45px;
}

.review-rating ol {
  margin: 0;
  padding: 0;
}

.review-rating ol li {
  list-style: none;
  display: block;
}

.single-comment .ratings {
  margin: 0;
  position: absolute;
  right: 0;
  top: 4px;
}

.single-comment .ratings span {
  color: #c4c2cb;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

.single-comment .ratings span i {
  color: #c4c2cb;
}

.review-form-area {
  position: relative;
  margin: 64px 0 28px;
}

.review-form-area > h5 {
  font-size: 20px;
  margin: 0 0 45px;
}

.comment-form-rating {
  display: flex;
  justify-content: flex-start;
  margin: 0 0;
}

.comment-form-rating label {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #5d5a67;
  margin: 0;
}

.comment-form-rating .ratings {
  margin-left: 15px;
}

.comment-form-rating .ratings span {
  line-height: .8;
  font-size: inherit;
  color: inherit;
  margin: 0;
}

.comment-form-rating .ratings span i {
  color: #c4c2cb;
}

.single-course-area .post-share {
  border: none;
}

.related-course {
  position: relative;
  margin: 72px 0 0;
}

.related-course h3 {
  font-size: 30px;
  margin: 0 0 43px;
}

.related-course-slider.owl-carousel {
  position: relative;
}

.related-course-slider.owl-carousel .owl-dots {
  position: relative;
  line-height: .8;
  margin: 0;
  text-align: center;
}

.related-course-slider.owl-carousel .owl-dots button {
  width: 10px;
  height: 10px;
  background: #dad7e3;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.related-course-slider.owl-carousel .owl-dots button.active,
.related-course-slider.owl-carousel .owl-dots button:hover {
  background: #5838fc;
}

.related-course-slider.owl-carousel .owl-dots button span {
  display: none;
}

.related-course-slider.owl-carousel .owl-stage-outer {
  margin: 0 -20px;
  padding: 0 20px;
}

.related-course-slider.owl-carousel .fci-details .author img {
  display: inline-block;
}

.theme-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
}

.vertical-align-top {
  vertical-align: top;
}

</style>